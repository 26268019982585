export const enum LOCALES {
    en = 'en',
    pl = 'pl',
    de = 'de',
    gb = 'gb',
    it = 'it',
    es = 'es',
    default = 'default',
}

export const enum PARAGRAPH_TYPES {
    callToAction = 'paragraph--call_to_action',
    contentSlider = 'paragraph--content_slider',
    ctaButton = 'paragraph--cta_button',
    documentBanner = 'paragraph--document_banner',
    heading = 'paragraph--heading',
    image = 'paragraph--image',
    heroImage = 'paragraph--hero_image',
    horizontalBanner = 'paragraph--horizontal_banner',
    keyVisual = 'paragraph--key_visual',
    linkSelector = 'paragraph--link_selector',
    slider = 'paragraph--slider',
    view = 'paragraph--view',
    richText = 'paragraph--rich_text',
    imageGallery = 'paragraph--image_gallery',
    ctaBanner = 'paragraph--cta_banner',
    infoBanner = 'paragraph--info_banner',
    author = 'paragraph--author',
    accordion = 'paragraph--accordion',
    video = 'paragraph--video',
    remoteVideo = 'paragraph--remote_video',
    animation = 'paragraph--animation',
    webform = 'paragraph--webform',
    search = 'paragraph--search',
    quote = 'paragraph--quote',
    infographicBanner = 'paragraph--infographic_banner',
    icon = 'paragraph--icon',
    reusableParagraph = 'paragraph--from_library',
    section = 'paragraph--section',
}

export const enum NODE_TYPES {
    product = 'node--product',
    article = 'node--article',
    page = 'node--page',
}

export const enum QUERY_PARAMS {
    query = 'query',
    page = 'page',
}

export const enum FETCH_PARAMS {
    viewsArguments = 'views-argument[]',
    include = 'include',
    sortBy = 'views-sort[sort_by]',
    sortOrder = 'views-sort[sort_order]',
}

export const enum SORT_ORDER {
    asc = 'ASC',
    desc = 'DESC',
}

export const SCREENS = {
    desktop: {name: 'desktop', size: 1024, media: '(min-width: 1024px)'},
    tablet: {name: 'tablet', size: 768, media: '(min-width: 768px) and (max-width: 1023px)'},
    mobile: {name: 'mobile', size: 640, media: '(max-width: 767px)'},
} as const;

export const IMAGE_FIELDS = {
    mobile: 'field_image_mobile',
    tablet: 'field_image_tablet',
    desktop: 'field_image',
} as const;

export const COLOR_VARIANTS = {
    'bg-primary': 'bg-primary',
    'bg-primary-foreground': 'bg-primary-foreground',
    'bg-secondary': 'bg-secondary',
    'bg-secondary-foreground': 'bg-secondary-foreground',
    'bg-accent': 'bg-gradient-to-tr from-accent to-accent-foreground',
    'bg-background': 'bg-background',
    'bg-white': 'bg-white',
    'bg-black': 'bg-black',
    'bg-lightgray': 'bg-lightgray',
    'bg-transparent': 'bg-transparent',
} as const;

export const COLOR_NAVBAR_VARIANTS = {
    'bg-white': 'lg:bg-white',
    'bg-black': 'lg:bg-black',
    'bg-lightgray': 'lg:bg-lightgray',
    'bg-primary': 'lg:bg-primary',
    'bg-primary-foreground': 'lg:bg-primary-foreground',
    'bg-secondary': 'lg:bg-secondary',
    'bg-secondary-foreground': 'lg:bg-secondary-foreground',
    'bg-accent': 'lg:bg-gradient-to-tr from-accent to-accent-foreground',
    'bg-background': 'lg:bg-background',
} as const;

export const TEXT_COLOR_VARIANTS = {
    'text-primary': 'text-primary',
    'text-black': 'text-black',
    'text-white': 'text-white',
} as const;

export const CONTAINER_WIDTH_VARIANTS = {
    narrow: 'narrow-container',
    wide: 'wide-container',
    full: 'full-container',
} as const;

export const PRODUCT_FIELD_TYPES = {
    productPicture: 'productPicture',
    packagingPicture: 'packagingPicture',
} as const;

export const STORAGE_KEYS = {
    articleFilters: 'article-filters',
    productFilters: 'product-filters',
} as const;

export const SPACING = 'space-y-8' as const;
