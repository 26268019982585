import GlobalStyles from '@/components/global-styles';
import {LanguageLinks} from '@/lib/types';
import {HydrationBoundary, QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {LanguageLinksProvider} from 'lib/language-links-context';
import {appWithTranslation} from 'next-i18next';
import {ReCaptchaProvider} from 'next-recaptcha-v3';
import {AppProps} from 'next/app';
import {useState} from 'react';

import 'styles/globals.css';
import 'styles/swiper-overrides.css';

interface MyAppProps extends AppProps {
    languageLinks?: LanguageLinks;
}

function App({Component, pageProps}: MyAppProps) {
    const {languageLinks} = pageProps;
    const [queryClient] = useState(() => new QueryClient());

    return (
        <ReCaptchaProvider defer reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}>
            <QueryClientProvider client={queryClient}>
                <LanguageLinksProvider languageLinks={languageLinks}>
                    <GlobalStyles />
                    <HydrationBoundary state={pageProps.dehydratedState}>
                        <Component {...pageProps} />
                    </HydrationBoundary>
                </LanguageLinksProvider>
            </QueryClientProvider>
        </ReCaptchaProvider>
    );
}

export default appWithTranslation(App);
