import type {DrupalClient} from 'next-drupal';
import type {DrupalLanguageMenuLink} from './interfaces';
import type {LanguageLinks, Translations} from './types';

import i18nConfig from '@/next-i18next.config';
import {DrupalJsonApiParams} from 'drupal-jsonapi-params';
import {GetStaticPropsContext} from 'next';
import {createContext, useContext} from 'react';
import {isMenuLinkAvailable} from './utils';

const LanguageLinksContext = createContext<LanguageLinks>(
    i18nConfig.i18n.locales.reduce((acc, locale) => {
        acc[locale] = {
            locale,
            title: locale,
            link: '',
        };
        return acc;
    }, {})
);

const getLanguageLinksDefaults = async (context: GetStaticPropsContext, drupal: DrupalClient) => {
    try {
        const languageMenu = await drupal.getMenu('languages', {
            locale: context.locale,
            defaultLocale: context.defaultLocale,
            params: new DrupalJsonApiParams()
                .addInclude(['field_icon.field_media_image'])
                .addFields('file--file', ['resourceIdObjMeta', 'uri'])
                .getQueryObject(),
        });
        return languageMenu?.tree || [];
    } catch (error) {
        console.error(error);
    }
    return [];
};

/**
 * From the site config and available node translations, create links to be used in the language switcher.
 */
export const createLanguageLinks = async (nodeTranslations?: Translations, context?: GetStaticPropsContext, drupal?: DrupalClient): Promise<LanguageLinks> => {
    const languageLinksDefaultsArray: DrupalLanguageMenuLink[] = await getLanguageLinksDefaults(context, drupal);
    const localLanguageLinks: LanguageLinks = i18nConfig.i18n.locales.reduce((acc, locale) => {
        acc[locale] = {
            locale,
            title: locale,
            link: nodeTranslations[locale] ?? '',
            defaultLink: '',
        };
        return acc;
    }, {});

    const languageLinks: LanguageLinks = languageLinksDefaultsArray.reduce((acc, link) => {
        if (isMenuLinkAvailable(link, context)) {
            const icon = {
                url: link.field_icon?.field_media_image?.uri?.url,
                width: link.field_icon?.field_media_image?.resourceIdObjMeta?.width || 64,
                height: link.field_icon?.field_media_image?.resourceIdObjMeta?.height || 64,
            };
            
            acc[link.field_country_code ?? link.title] = {
                locale: link.field_country_code ?? link.title,
                title: link.title,
                link: nodeTranslations[link.field_country_code] ?? '',
                defaultLink: localLanguageLinks[link.field_country_code] ? '' : link.url,
                ...(icon.url ? {icon} : {}),
            };
        }
        return acc;
    }, localLanguageLinks);

    return languageLinks;
};

/**
 * Generates a language links object for a page that is created in next only.
 * @param path
 *   The path of the page.
 * @param context
 *   The context.
 */
export const createLanguageLinksForNextOnlyPage = (path: string, context: GetStaticPropsContext): LanguageLinks => {
    const languageLinks = JSON.parse(JSON.stringify(i18nConfig.i18n.locales));
    context.locales.forEach(locale => {
        languageLinks[locale].path = languageLinks[locale].path === '/' ? path : `${languageLinks[locale].path}${path}`;
    });
    return languageLinks;
};

/**
 * Provide the language links context.
 */
export const LanguageLinksProvider = ({languageLinks, children}: {languageLinks?: LanguageLinks; children: React.ReactNode}) => (
    <LanguageLinksContext.Provider value={languageLinks}>{children}</LanguageLinksContext.Provider>
);

/**
 * Access the language links from the language links context.
 */
export const useLanguageLinks = () => useContext(LanguageLinksContext);
