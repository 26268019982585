import {notoSansDisplay} from '@/lib/fonts';

const GlobalStyles = () => (
    <style jsx global>{`
        :root {
            --font-noto-sans: ${notoSansDisplay.style.fontFamily};
        }
    `}</style>
);

export default GlobalStyles;
