/** @type {import('next-i18next').UserConfig} */

// Parse the NEXT_PUBLIC_DOMAIN_CONFIG environment variable
const domainConfig = process.env.NEXT_PUBLIC_DOMAIN_CONFIG ? JSON.parse(process.env.NEXT_PUBLIC_DOMAIN_CONFIG) : {};

module.exports = {
    i18n: {
        defaultLocale: 'default',
        locales: Object.keys(domainConfig)?.length ? ['default', ...Object.keys(domainConfig)] : ['default', 'en'],
        localeDetection: false,
    },
};
