import clsx, {ClassValue} from 'clsx';
import {GetStaticPropsContext} from 'next';
import {DrupalMenuLinkContent} from 'next-drupal';
import {twMerge} from 'tailwind-merge';
import {QUERY_PARAMS} from './constants';

export const formatDate = (input: string): string => {
    const date = new Date(input);
    return date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
};

export const absoluteUrl = (input: string) => `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}${input ?? '/'}`;

export const controlInert = (node: HTMLElement, condition: boolean) => {
    if (!node) return;

    if (condition) {
        node.setAttribute('inert', '');
    } else {
        node.removeAttribute('inert');
    }
};

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

export const splitArrayIntoChunks = (arr: any[], chunkSize: number): any[][] => {
    const result: any[][] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize));
    }
    return result;
};

export const objectToQueryParams = (obj: Record<string, any>): string => {
    const queryParams = [];

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            if (value !== null && value !== undefined) {
                queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        }
    }

    return queryParams.join('&');
};

export const queryParamsToObject = (queryParams: string): Record<string, string> => {
    const obj: Record<string, string> = {};
    const pairs = queryParams.split('&');

    for (const pair of pairs) {
        const [key, value] = pair.split('=');
        if (key) {
            obj[decodeURIComponent(key)] = value ? decodeURIComponent(value) : '';
        }
    }

    return obj;
};

export const filterObjectByValidKeys = (obj: Record<string, any>, validKeys: string[]): Record<string, any> => {
    const result: Record<string, any> = {};

    for (const key in obj) {
        if (validKeys.includes(key)) {
            result[key] = obj[key];
        }
    }

    return result;
};

export const getImageAligment = (classString: string) => {
    const twAligments = {
        'align-left': 'float-left',
        'align-right': 'float-right',
        'align-center': 'mx-auto',
    };
    const aligments = ['align-left', 'align-right', 'align-center'];
    return twAligments[aligments.find(aligment => classString.includes(aligment))] ?? 'mr-auto';
};

export const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const getQueryParams = (asPath: string) =>
    asPath?.includes(QUERY_PARAMS.page) ? asPath?.substring(asPath.lastIndexOf('/') + 1) : asPath?.split('?')[1];

export const getFilters = (filters: string) => (sessionStorage ? sessionStorage.getItem(filters) : '');

export const saveFilters = (filters: string, asPath: string) => {
    if (sessionStorage && asPath) {
        const filtersParams = getQueryParams(asPath);
        if (filtersParams) {
            sessionStorage.setItem(filters, filtersParams);
            return true;
        }
        sessionStorage.removeItem(filters);
        return false;
    }

    return false;
};

export const getFiltersLink = (url: string, filters: string) => {
    if (!url) {
        return '';
    }

    return filters ? `${url}${filters?.includes(QUERY_PARAMS.page) ? '/' : '?'}${filters}` : `${url}`;
};

export const isMenuLinkAvailable = (item: DrupalMenuLinkContent, context: GetStaticPropsContext): boolean => {
    return (item?.field_languages.length && item.field_languages.includes(context.locale)) || !item?.field_languages.length
}
